// @flow
import * as React from 'react';
import { Alert, Button, Checkbox, DatePicker, Form, Modal, Row, Select } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Moment } from 'moment';
import { keys } from 'lodash';
import { useForm } from 'antd/es/form/Form';
import type { TGlobalState } from '../../../types';
import { SPACING } from '../../../constants/style.constants';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { clearShiftForAction, shiftForAction } from '../../../store/actions/shift.actions';
import type { TOperationsTime, TShiftLabel } from '../../../types/barge_clerk.types';
import { OPERATIONS_TIME_REASON_MESSAGES } from '../../../config/messages/operationsTimeReasons';
import { operationsTimeAction } from '../../../store/actions';
import { VisitSummary } from '../../atoms/modals/VisitSummary';
import moment from 'moment/moment';

type TProps = {
  clearShiftForAction: typeof clearShiftForAction,
  hideModal: Function,
  operationsTimeAction: typeof operationsTimeAction,
  shiftForAction: typeof shiftForAction,
  shifts: TShiftLabel[],
  visible: boolean,
  visit: Object,
  showUndoOpsConfirmModal: Function,
};

export function OPSTimeModalComponent(props: TProps) {
  const intl = useIntl();
  const [form] = useForm();

  if (!props.visit) return null;

  const onClose = () => {
    props.hideModal();
    form.resetFields();
    props.clearShiftForAction();
  };

  const onSubmit = () => {
    form.validateFields().then((values) => {
      const operationsTime: TOperationsTime = {
        operationsTime: values.operationsTime.format(),
        shift: props.shifts.find((shift: TShiftLabel) => values.shift === shift.id),
        reason: values.reason,
        saldo: !!values.saldo,
      };
      props.operationsTimeAction(props.visit, operationsTime);
      onClose();
    });
  };

  const openConfirmation = () => {
    props.hideModal();
    props.showUndoOpsConfirmModal();
  };

  const shiftValidator = (rule, value) => {
    const saldoChecked = form.getFieldValue('saldo');
    if (value === props.visit.shiftId && saldoChecked) {
      return Promise.reject(new Error(intl.formatMessage(VALIDATION_MESSAGES.ops_time_within_current_shift_if_saldo)));
    }
    return Promise.resolve();
  };

  const range = (start: number, end: number) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <Modal
      title={<FormattedMessage id='molecules.modals.ops_time.title' defaultMessage='Change OPT' />}
      centered open={props.visible} footer={null} onCancel={onClose} maskClosable width={700}>
      <Row type='flex' justify='start' style={{ marginBottom: SPACING.COLLAPSE }}>
        <VisitSummary visit={props.visit} />
      </Row>
      <Row>
        <Form form={form} layout='vertical' onFinish={onSubmit}>
          <Form.Item
            name='operationsTime'
            initialValue={null}
            rules={[
              {
                message: intl.formatMessage(VALIDATION_MESSAGES.null),
                required: true,
              },
            ]}
            label={
              <strong>
                <FormattedMessage id='molecules.modals.ops_time.date_time'
                                  defaultMessage='Date / time' />
              </strong>
            }
          >
            <DatePicker
              size='large'
              style={{ width: '30%' }}
              format='YYYY-MM-DD HH:mm'
              showTime={{ secondStep: 60 }}
              placeholder={intl.formatMessage({
                id: 'molecules.modals.ops_time.select_date',
                defaultMessage: 'Select date / time',
              })}
              onChange={(date: Moment) => {
                if (date) {
                  props.shiftForAction(date.seconds(0).format());
                } else {
                  props.clearShiftForAction();
                }
              }}
              disabledDate={(current) => {
                return (current && moment(current).add(7, 'days').isBefore(moment().endOf('day'))) ||
                  (current && moment(current).subtract(6, 'days').isAfter(moment().endOf('day')));
              }}
              disabledTime={(selected) => {
                if(!selected) return;
                // first day, disable 0-6
                if(!moment().endOf('day').isBefore(moment(selected).add(6, 'days').endOf('day'))) {
                  return {
                    disabledHours: () => range(0, 6),
                  };
                }
                // last day, disable 6-24
                if(!moment().endOf('day').isAfter(moment(selected).subtract(6, 'days').endOf('day'))) {
                  return {
                    disabledHours: () => range(6, 24),
                  };
                }
                return;
              }}
            />
          </Form.Item>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Form.Item
              name='shift'
              rules={[
                {
                  message: intl.formatMessage(VALIDATION_MESSAGES.null),
                  required: true,
                },
                {
                  validator: shiftValidator,
                },
              ]}
              label={
                <strong>
                  <FormattedMessage id='molecules.modals.ops_time.shift' defaultMessage='Shift' />
                </strong>
              }
              style={{ marginRight: SPACING.COLLAPSE, width: 195 }}
            >
              <Select size='large' placeholder={<FormattedMessage
                id='molecules.modals.ops_time.shift.placeholder'
                defaultMessage='Select a shift' />}>
                <Select.OptGroup>
                  {props.shifts
                    ? props.shifts.map((shift) => (
                      <Select.Option key={shift.id} value={shift.id}>
                        Shift {shift.label} - {shift.date}
                      </Select.Option>
                    ))
                    : null}
                </Select.OptGroup>
              </Select>
            </Form.Item>
            <Form.Item
              name='saldo'
              initialValue={false}
              valuePropName='checked'
              label={
                <strong>
                  <FormattedMessage id='molecules.modals.ops_time.saldo'
                                    defaultMessage='Move saldo' />
                </strong>
              }
            >
              <Checkbox />
            </Form.Item>
          </div>
          <Form.Item
            name='reason'
            rules={[
              {
                message: intl.formatMessage(VALIDATION_MESSAGES.null),
                required: true,
              },
            ]}
            label={
              <strong>
                <FormattedMessage id='molecules.modals.ops_time.reason' defaultMessage='Reason' />
              </strong>
            }
          >
            <Select size='large'
                    placeholder={<FormattedMessage id='molecules.modals.ops_time.reason.placeholder'
                                                   defaultMessage='Select a reason for change' />}>
              <Select.OptGroup>
                {keys(OPERATIONS_TIME_REASON_MESSAGES).map((key) => (
                  <Select.Option key={key} value={key}>
                    {intl.formatMessage(OPERATIONS_TIME_REASON_MESSAGES[key])}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            </Select>
          </Form.Item>

          <div style={{ marginTop: 48 }}>
            <Alert
              message={<FormattedMessage id='opt_time_modal.warning.gangs.title'
                                         defaultMessage='Watch out' />}
              description={<FormattedMessage id='opt_time_modal.warning.gangs.message'
                                             defaultMessage='If the visit gets moved to another shift, the gangs assigned to this visit will be removed.' />}
              type='warning'
              style={{ marginBottom: 10 }}
            />

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Form.Item style={{ marginBottom: 0, marginRight: SPACING.COLLAPSE }}>
                <Button type='default' htmlType='submit'>
                  <FormattedMessage id='general.submit_change' defaultMessage='Submit change' />
                </Button>
              </Form.Item>
              {props.visit.operationsTime && (
                <button className='visit-top-link' onClick={openConfirmation}
                        style={{ marginRight: SPACING.COLLAPSE }} type='button'>
                  <FormattedMessage id='opt_time_modal.undo_opt_time'
                                    defaultMessage='Undo OPT time' />
                </button>
              )}
              <button className='visit-top-link' onClick={onClose} style={{ padding: 0 }}
                      type='button'>
                <FormattedMessage id='general.cancel_change' defaultMessage='Cancel change' />
              </button>
            </div>
          </div>
        </Form>
      </Row>
    </Modal>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  shifts: state.bargeClerks.shifts,
});

export const OPSTimeModal = connect<any, Object, _, _, _, _>(mapStateToProps, {
  shiftForAction,
  clearShiftForAction,
  operationsTimeAction,
})(injectIntl(OPSTimeModalComponent));
